/* eslint no-console:0 */
// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.
//
// To reference this file, add <%= javascript_pack_tag 'application' %> to the appropriate
// layout file, like app/views/layouts/application.html.erb


// Uncomment to copy all static images under ../images to the output folder and reference
// them with the image_pack_tag helper in views (e.g <%= image_pack_tag 'rails.png' %>)
// or the `imagePath` JavaScript helper below.
//
// const images = require.context('../images', true)
// const imagePath = (name) => images(name, true)

import 'core-js/stable';
import 'regenerator-runtime/runtime';
import '../css/application.css';
// import './carousel';
// import './slider';
// import './datepicker';
document.addEventListener('turbolinks:load', () => {
  if (document.querySelector('.datepicker')) {
    import('./datepicker').then(({ default: initializeFlatpickr }) => {
      initializeFlatpickr();
    });
  }
});

import './custom';
import './lazyLoading';
import './gtm_events';
import './book_form_submitted';
import './contact_form_submitted';

// import sbjs from 'sourcebuster';

// document.addEventListener("turbolinks:load", function () {
//   const isCookieConsentGivenSbjs = () => {
//     const consentCookieSbjs = document.cookie.split(';')
//       .map(cookie => cookie.trim())
//       .find(cookie => cookie.startsWith('__cookieAccept=') && cookie.endsWith('accepted'));

//     return consentCookieSbjs !== undefined;
//   };

//   if (!isCookieConsentGivenSbjs()) {
//     return;
//   }

//   sbjs.init();
// });



// var sbjs = require('sourcebuster');

// sbjs.init({
//   domain: 'http://localhost:3000/'
// });
// import "./datepicker";
