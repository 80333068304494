// document.addEventListener('turbolinks:load', (event) => {
//   window.dataLayer = window.dataLayer || [];
//   const contactBtn = document.querySelector('.contact-button-gtm-event');
//   const bookBtn = document.querySelector('.book-button-gtm-event');
//   const bookBtnForm = document.querySelector('.book-button-gtm-event-form');

//   if (contactBtn) {
//     contactBtn.addEventListener('click', () => {
//       dataLayer.push({ 'event': 'contact_us_clicked' });
//     });
//   }

//   if (bookBtn) {
//     bookBtn.addEventListener('click', () => {
//       dataLayer.push({ 'event': 'book_tour_clicked' });
//     });
//   }


//   if (bookBtnForm) {
//     bookBtnForm.addEventListener('click', (e) => {
//       dataLayer.push({ 'event': 'book_tour_clicked' });
//     });
//   }
// });




document.addEventListener('turbolinks:load', (event) => {
  window.dataLayer = window.dataLayer || [];

  // // Check if cookie consent is given
  // const isCookieConsentGiven = () => {
  //   const consentCookie = document.cookie.split(';')
  //     .map(cookie => cookie.trim())
  //     .find(cookie => cookie.startsWith('__cookieAccept=') && cookie.endsWith('accepted'));

  //   return consentCookie !== undefined;
  // };

  // // // If consent is not given, do not interact with dataLayer
  // if (!isCookieConsentGiven()) {
  //   // console.log('Cookie consent not given. GTM events will not be tracked.');
  //   document.cookie = '_ga_W44QSMQ4Z7=; expires=Thu, 01 Jan 1970 00:00:01 GMT; path=/;domain=.onecowork.com';
  //   document.cookie = '_ga=; expires=Thu, 01 Jan 1970 00:00:01 GMT; path=/;.onecowork.com';
  //   return;
  // }

  // console.log('Cookie consent given. GTM events will be tracked.');

  const contactBtn = document.querySelector('.contact-button-gtm-event');
  const bookBtn = document.querySelector('.book-button-gtm-event');
  const bookBtnForm = document.querySelector('.book-button-gtm-event-form');

  if (contactBtn) {
    contactBtn.addEventListener('click', () => {
      dataLayer.push({ 'event': 'contact_us_clicked' });
    });
  }

  if (bookBtn) {
    bookBtn.addEventListener('click', () => {
      dataLayer.push({ 'event': 'book_tour_clicked' });
    });
  }

  if (bookBtnForm) {
    bookBtnForm.addEventListener('click', (e) => {
      dataLayer.push({ 'event': 'book_tour_clicked' });
    });
  }
});
