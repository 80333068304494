document.addEventListener("turbolinks:load", function() {

  //   const trueButton = document.getElementById('schedule-true');
  //   const falseButton = document.getElementById('schedule-false');
  //   const submitButton = document.getElementById('submit-button');
  //   if (trueButton && falseButton && submitButton) {
  //   const originalSubmitText = submitButton.value;

  //   trueButton.addEventListener('change', function () {
  //     if (this.checked) {
  //       const locale = this.dataset.locale; // Read the data-locale attribute

  //       // Determine the text based on the locale
  //       let buttonText;
  //       if (locale === 'es') {
  //         buttonText = 'Programar Visita';
  //       } else if (locale === 'ca') {
  //         buttonText = 'Programar Visita'; // Catalan translation; adjust as needed
  //       } else {
  //         buttonText = 'Schedule Tour';
  //       }

  //       // Set the text of the submit button
  //       submitButton.value = buttonText;
  //     }
  //   });

  //   falseButton.addEventListener('change', function () {
  //     if (this.checked) {
  //       submitButton.value = originalSubmitText;
  //     }
  //   });
  // }


  const gtmDataDiv = document.querySelector('#book-tour-form-gtm-data');
  if (!gtmDataDiv) {
    return;
  }
  dataLayer = window.dataLayer || [];

  // const isCookieConsentGivenBookForm = () => {
  //   const consentCookieBookForm = document.cookie.split(';')
  //     .map(cookie => cookie.trim())
  //     .find(cookie => cookie.startsWith('__cookieAccept=') && cookie.endsWith('accepted'));

  //   return consentCookieBookForm !== undefined;
  // };

  // if(!isCookieConsentGivenBookForm()) {
  //     return;
  //   }
  dataLayer.push({
    'language': gtmDataDiv.dataset.language,
    'location': gtmDataDiv.dataset.location,
    'submitted_from': gtmDataDiv.dataset.submittedFrom,
    'event': 'book_tour_form_submit'
  });
});
