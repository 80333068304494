/*Toggle dropdown list*/
/*https://gist.github.com/slavapas/593e8e50cf4cc16ac972afcbad4f70c8*/

// document.addEventListener('turbolinks:load', (event) => {
//   // let disableDrift = !!document.querySelector(`[data-behaviour='disable-drift']`);
//   // let driftContainer = document.querySelector('#drift-widget-container');

//   // disableDrift ? driftContainer.classList.add('hidden') : driftContainer.classList.remove('hidden');

//   let navMenuDiv = document.getElementById("nav-content");
//   let navMenu    = document.getElementById("nav-toggle");

//   document.onclick = check;

//   function check(e) {
//     let target = (e && e.target) || (event && event.srcElement);
//     // hiddenMenu;

//     //Nav Menu
//     if (!checkParent(target, navMenuDiv)) {
//       document.firstElementChild.style.overflow = "hidden";
//       // click NOT on the menu
//       if (checkParent(target, navMenu)) {
//         document.firstElementChild.style.overflow = "hidden";
//         // click on the link
//         if (navMenuDiv.classList.contains("hidden")) {
//           navMenuDiv.classList.remove("hidden");
//           // navMenuDiv.style.paddingBottom = "180px";
//           document.firstElementChild.style.overflow = "hidden";
//           document.firstElementChild.style.position = "fixed";
//           document.firstElementChild.style.right = "0px";
//           document.firstElementChild.style.left = "0px";
//         } else {
//           navMenuDiv.classList.add("hidden");
//           document.firstElementChild.style.overflow = "visible";
//           document.firstElementChild.style.position = "static";
//         }
//       } else {
//         // click both outside link and outside menu, hide menu
//         navMenuDiv.classList.add("hidden");
//         document.firstElementChild.style.overflow = "visible";
//         document.firstElementChild.style.position = "static";
//       }
//     }
//   }
//   function checkParent(t, elm) {
//     while (t.parentNode) {
//       if (t === elm) {
//         return true;
//       }
//       t = t.parentNode;
//     }
//     return false;
//   }

//   $('#banner-close').click(function (){
//     $("#covid-banner").fadeOut();
//     document.cookie = "ocw_hide_covid=true";
//   });
// });





//////////////////////////



document.addEventListener('turbolinks:load', (event) => {

  let navMenuDiv = document.getElementById("nav-content");
  let navMenu = document.getElementById("nav-toggle");

  if (navMenuDiv.classList.contains('hidden')) {
    document.firstElementChild.style.overflow = "visible";
    document.firstElementChild.style.position = "static";
  }

  // Toggle menu when clicking on the toggle button
  navMenu.addEventListener('click', function () {
    toggleMenu();
  });

  // Close menu when clicking outside
  document.addEventListener('click', function (e) {
    if (!navMenuDiv.contains(e.target) && !navMenu.contains(e.target) && !navMenuDiv.classList.contains('hidden')) {
      closeMenu();
    }
  });

  function toggleMenu() {
    if (navMenuDiv.classList.contains('hidden')) {
      navMenuDiv.classList.remove('hidden');
      document.firstElementChild.style.overflow = "hidden";
      document.firstElementChild.style.position = "fixed";
      document.firstElementChild.style.right = "0px";
      document.firstElementChild.style.left = "0px";
    } else {
      closeMenu();
    }
  }

  function closeMenu() {
    navMenuDiv.classList.add('hidden');
    document.firstElementChild.style.overflow = "visible";
    document.firstElementChild.style.position = "static";
  }
  $('#banner-close').click(function () {
    $("#covid-banner").fadeOut();
    document.cookie = "ocw_hide_covid=true";
  });
});


//////////////////////////










function scrollFunction() {
  if (document.body.scrollTop > 650 || document.documentElement.scrollTop > 650) {
    document.getElementById("header").style.position = "fixed";
    document.getElementById("header").style.background = "white";
    document.getElementById("header").style.padding = "0.5rem 2rem";
    document.getElementById("header").style.boxShadow = "0 0 20px rgba(0, 0, 0, 0.05)";
    document.getElementById("top-section").style.marginTop = "100px";
  } else {
    document.getElementById("header").style.position = "relative";
    document.getElementById("header").style.background = "transparent";
    document.getElementById("header").style.padding = "1.5rem 2rem";
    document.getElementById("header").style.boxShadow = "none";
    document.getElementById("top-section").style.marginTop = "0px";
  }
}

// When the user click on CTA that scrolls to anchor element, add margin
function scrollTop() {
  var elmnt = document.getElementById("locations-form");
  if(elmnt){
    elmnt.scrollTop = 800;
  }
}

function sticky_relocate() {
  if($('#blog-post').length > 0){
    var window_top = $(window).scrollTop();
    var div_top = $('#blog-post').offset().top;
    var stop_top = $('#footer').offset().top;
    if (window_top > div_top && window_top < stop_top) {
        $('.sticky-element').addClass('stick');
    }
    else {
        $('.sticky-element').removeClass('stick');
    }
  }
}

// When the user scrolls down 50px from the top of the document, fix header
window.onscroll = function() {
  scrollFunction();
  scrollTop();
};

$(document).ready(function () {
  if($('#blog-post').length > 0){
    $(window).scroll(sticky_relocate);
  }
});

$("#new_book_form").submit(function (e) {
  $("input[type=submit]").attr("disabled", true);
  $("input[type=submit]").css("opacity", "0.5");
  $("input[type=submit]").hide();
  $(".submit-parent").append("<div class='lds-ellipsis'><div></div><div></div><div></div><div></div></div>");
});
