document.addEventListener("turbolinks:load", function () {

  const gtmContactDataDiv = document.querySelector('#contact-form-gtm-data');
  if (!gtmContactDataDiv) {
    return;
  }
  dataLayer = window.dataLayer || [];

  // const isCookieConsentGivenBookForm = () => {
  //   const consentCookieBookForm = document.cookie.split(';')
  //     .map(cookie => cookie.trim())
  //     .find(cookie => cookie.startsWith('__cookieAccept=') && cookie.endsWith('accepted'));

  //   return consentCookieBookForm !== undefined;
  // };

  // if (!isCookieConsentGivenBookForm()) {
  //   return;
  // }
  dataLayer.push({
    'language': gtmContactDataDiv.dataset.language,
    'event': 'contact_us_form_submit'
  });
});
